import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RadioOptions } from './RadioOptionsModel';

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
})
export class RadioGroupComponent implements OnChanges {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  @Input() options: RadioOptions[] = [];
  @Input() isMobile = false;
  @Output() backClicked: EventEmitter<void> = new EventEmitter();

  selectedOption: { value: string | null } = {
    value: null,
  };

  redirect() {
    const path = this.options.find(
      (o) => o.id === this.selectedOption.value
    )?.path;

    this.router.navigate([path], {
      relativeTo: this.activatedRoute,
    });
  }

  goBack() {
    this.backClicked.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currentIds = changes['options'].currentValue?.map(
      (o: RadioOptions) => o.id
    );
    const previousIds = changes['options'].previousValue?.map(
      (o: RadioOptions) => o.id
    );
    const diff = currentIds?.filter((x: string) => !previousIds?.includes(x));

    if (diff.length) {
      this.selectedOption.value = null;
    }
  }

  public get isBiggerThanThree() {
    return this.options.length > 2;
  }
}
