import { PortableTextBlock } from '@portabletext/types';
import { ImageViewModel } from '../../app/components/images-carousel/images-carousel.types';

export interface StepModel {
  id: string;
  stepName: string;
  pageHeader: string;
  buttonText: string;
  introduction: PortableTextBlock[] | string;
  additionalContent: PortableTextBlock[] | string;
  wisdom: PortableTextBlock[] | string;
  keyPhysicalExam: PortableTextBlock[] | string;
  keyMedicalHistory: PortableTextBlock[] | string;
  keyDiagnostics: PortableTextBlock[] | string;
  clinicalPearls: PortableTextBlock[] | string;
  doNotMiss: PortableTextBlock[] | string;
  tests: PortableTextBlock[] | string;
  stepHasTest: boolean;
  images: ImageViewModel[];
  nextSteps: string[];
  differentials: string[];
  citations: string[];
  redFlags?: string;
  decisionRule?: string;
  pivotalQuestion?: string;
  commentOnPivotalQuestion?: string;
}

export interface StepItem {
  id: string;
  type: StepType;
}

export interface StepContextualInfo {
  keyPhysicalExam: string;
  keyMedicalHistory: string;
  keyDiagnostics: string;
  clinicalPearls: string;
  redFlags: string;
}

export interface HistoryStepItem {
  id: string;
  title: string;
  description: string;
}

export enum StepType {
  DIFFERENTIAL = 'differential',
  QUESTION = 'question',
  REVIEW = 'review',
}

export enum StepContentName {
  CLINICAL_PEARLS = 'clinical_pearls',
  DECISION_RULE = 'decision_rule',
  DIFFERENTIALS = 'differential_diagnosis_focused_differentials',
  ILLNESS_SCRIPT = 'illnes_script',
  KEY_DIAGNOSTICS = 'key_diagnostics',
  KEY_MEDICAL_HISTORY = 'key_medical_history',
  KEY_PHYSICAL_EXAM = 'key_physical_exam',
  PIVOTAL_QUESTION = 'pivotal_question',
  RED_FLAGS = 'red_flags',
  COMMENT_ON_PIVOTAL_QUESTION = 'comment_on_pivotal_question',
}

export enum HomeContentName {
  REFERENCES = 'references',
}
