<form (submit)="onSubmit($event)">
    <button #searchBtn aria-label="search" class="search-button" type="submit">
        <img src='/assets/search-dark.svg' alt=''>
    </button>
    <input
        #searchInput class="form-control mr-sm-2 search-input" aria-label="Search"
        [(ngModel)]="searchText" id="search-input" name="search-input" (input)="onSearchTextChangeHandler($event)"
        [placeholder]="placeholder" />
    <button *ngIf="searchText !== ''" aria-label="clear searchbar" class="clear-search-button" (click)="clearSearch($event)">
        <img src='/assets/clear-search.svg' alt=''>
    </button>
</form>