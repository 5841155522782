import { ImageViewModel } from 'src/app/components/images-carousel/images-carousel.types';

export interface IllnessScriptModel {
  id: string;
  illnessName: string;
  overview: string;
  classicPresentation: string;
  uncommonPresentation: string;
  pathophysiology: string;
  emergent: boolean;
  mustNotMiss: boolean;
  frequency: string;
  riskFactors: string;
  symptoms: string;
  signs: string;
  timeCourse: string;
  workup: string;
  management: string;
  pearlsAndPitfalls: string;
  ruledOut: boolean;
  images?: ImageViewModel[];
}

export enum IllnessScriptNodeTemplate {
  Summary = 'summary',
  ClassicPresentation = 'classic_presentation',
  UncommonPresentation = 'uncommon_presentation',
  Pathophysiology = 'pathophysiology',
  DiagnosesType = 'diagnoses_type',
  Frequency = 'frequency',
  EtiologyRiskFactors = 'etiology_risk_factors',
  SymptomsAll = 'symptoms_all',
  SignsAll = 'signs_all',
  TimeCourse = 'time_course',
  WorkUp = 'work_up',
  FundamentalsOfManagement = 'fundamentals_of_management',
  PearlsAndPitfalls = 'pearls_and_pitfalls',
}

export enum DiagnosesType {
  Emergent = 'Emergent',
  MustNotMiss = 'Must Not Miss',
}

export type Differentials = {
  responsive: boolean;
  icon?: string;
  caption?: string;
  color?: string;
  activeTab?: number;
  filters?: boolean;
};
