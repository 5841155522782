<div class="step-cta-wrapper">
  <div class="step-cta"
    [ngClass]="{'step-cta-differential': type === 'differential', 'step-cta-review': type === 'review'}">
    <ng-container *ngIf="type === 'question'">
        <ng-container *ngIf="options !== null && options.length > 0">
          <ng-container *ngIf="options.length === 1">
              <app-button [type]="'secondary'" (click)="onClick(0)" [label]='options[0].label' />
            </ng-container>
            <ng-container *ngIf="options.length >= 2">
              <app-radio-group [options]="options" (backClicked)="onGoBack()" [isMobile]="isMobile"/>
            </ng-container>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="type === 'differential' && options !== null && options.length > 0">
            <app-button *ngIf="!isMobile" [type]="'outline-blue'" (click)="onGoBack()" [label]='"Back"' />
            <app-button [type]="'secondary'" (click)="onClick(0)" [label]='options[0].label' />
    </ng-container>
    <ng-container *ngIf="type === 'review' && options !== null && options.length > 0">
          <app-button *ngIf="!isMobile" [type]="'outline-blue'"
            (click)="onGoBack()" [label]="'Back'" />
          <app-button [type]="'secondary'"
          (click)="onClick(0)" [label]='options[0].label' [id]="options[0].id" />
    </ng-container>
  </div>
</div>