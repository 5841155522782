import { Injectable } from '@angular/core';

@Injectable()
export class ScrollService {
  scrollContentToTop() {
    document
      .getElementsByClassName('content-wrapper')[0]
      ?.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
