import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  getAllIllnessScripts,
  getAllIllnessScriptsSuccess,
  getIllnessScript,
  getIllnessScriptsByDDxId,
  getIllnessScriptsByDDxIdSuccess,
  getIllnessScriptsByProblemId,
  getIllnessScriptsByProblemIdSuccess,
  getIllnessScriptsByStepId,
  getIllnessScriptsByStepIdSuccess,
  getIllnessScriptSuccess,
} from './illness-script.actions';
import { map, mergeMap } from 'rxjs';
import { IllnessScriptService } from '../illness-script.service';

@Injectable()
export class IllnessScriptListEffect {
  constructor(
    private actions$: Actions,
    private illnessScriptListService: IllnessScriptService
  ) {}

  loadIllnessScripts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllIllnessScripts),
      mergeMap(() => {
        return this.illnessScriptListService.get().pipe(
          map((data) => {
            return getAllIllnessScriptsSuccess({ data });
          })
        );
      })
    )
  );

  loadIllnessScriptsByIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getIllnessScript),
      mergeMap((action) => {
        return this.illnessScriptListService
          .getById(action.id)
          .pipe(map((data) => getIllnessScriptSuccess({ data })));
      })
    )
  );

  loadIllnessScriptsByProblemIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getIllnessScriptsByProblemId),
      mergeMap((action) => {
        return this.illnessScriptListService
          .getByProblemId(action.problemId)
          .pipe(
            map((data) => {
              return getIllnessScriptsByProblemIdSuccess({
                problemId: action.problemId,
                data,
              });
            })
          );
      })
    )
  );

  loadIllnessScriptsByStepIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getIllnessScriptsByStepId),
      mergeMap((action) => {
        return this.illnessScriptListService
          .getByStepId(action.problemId, action.stepId)
          .pipe(
            map((data) =>
              getIllnessScriptsByStepIdSuccess({
                stepId: action.stepId,
                data,
              })
            )
          );
      })
    )
  );

  loadIllnessScriptsByDdxIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getIllnessScriptsByDDxId),
      mergeMap(
        (action: { problemId: string; stepId: string; ddxId: string }) => {
          return this.illnessScriptListService
            .getByDDxId(action.problemId, action.stepId, action.ddxId)
            .pipe(
              map((data) =>
                getIllnessScriptsByDDxIdSuccess({
                  ddxId: action.ddxId,
                  data,
                })
              )
            );
        }
      )
    )
  );
}
