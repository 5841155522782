export const environment = {
  apiBaseUrl:
    'https://api-dev.cr.nonprod.mheducation.com/clinical-reasoning-middleware',
  fileBaseUrl: 'assets/data',
  env: 'dev',
  featureFlags: {
    searchComponent: true,
    clinicalReasoningJumpTo: true,
  },
};
