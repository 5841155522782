import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ddxTableState } from './ddx-table.reducer';

export const ddxTableFeatureKey = 'DDxTable';

export const selectDDxTableState =
  createFeatureSelector<ddxTableState>(ddxTableFeatureKey);

export const selectDDxTable = createSelector(
  selectDDxTableState,
  (state: ddxTableState) => {
    return state.ddxTable;
  }
);

export const selectDDxTableLoading = createSelector(
  selectDDxTableState,
  (state: ddxTableState) => state.loading
);

export const selectDDxTableError = createSelector(
  selectDDxTableState,
  (state: ddxTableState) => state.error
);
