import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-checkbox-button',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './checkbox-button.component.html',
  styleUrls: ['./checkbox-button.component.scss'],
})
export class CheckboxButtonComponent {
  @Input() label = '';
  @Input() value = '';
  @Input() count = 0;
  @Input() checked = false;
  @Input() checkedBackground: 'dark-blue' | 'light-blue' = 'dark-blue';
  @Output() onChangeCheckbox = new EventEmitter<Event>();
  @ViewChild('checkRef') checkRef!: ElementRef;
  @ViewChild('labelRef') labelRef!: ElementRef;

  onChange(event: Event) {
    this.onChangeCheckbox.emit(event);
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      this.labelRef.nativeElement.click();
      this.labelRef.nativeElement.focus();
    }
  }

  toggleButtonClick() {
    this.checkRef.nativeElement.click();
  }
}
