<div class="images-wrapper" [class.imageWrapperGrid]="mode === 'grid'">
  <div class="image-frame" *ngFor='let image of images; index as i'>
    <div 
      [ariaLabel]='image.altText'
      data-testId='main-carousel-image'
      (keydown)="onKeyDown($event, image, i)" 
      tabindex="0" role="img" class="image" 
      [style.backgroundImage]="'url('+image.url+')'" 
    >
    </div>
    <button 
      (click)='setActiveImage(image, i)'
      class='zoom-in'
      aria-label="click to zoom in on image"
    >
      <img src='assets/zoom-in.svg' alt='' 
      (load)="onImageLoad()" 
    >
    </button>
  </div>
</div>
<app-modal #modal>
  <div class="active-image">
    <div class="image-holder">
      <img  
        *ngIf="activeImage"
        (load)="onLoadActiveImage()"
        [src]='activeImage.url' 
        [alt]='activeImage.altText' 
        data-testId='detail-carousel-image' 
      />
    </div>

    <div class="controls">
      <button (click)="navigateImage(-1)"><</button>
      <button tabindex="-1">{{detailIndex}} of {{images.length}}</button>
      <button (click)="navigateImage(1)">></button>
    </div>
  </div>
</app-modal>