import { Component, Input } from '@angular/core';
import { illnessTypes } from './illness.types';
import { TextboxModule } from '../textbox/textbox.module';
import { CommonModule, Location } from '@angular/common';
import { IllnessScriptModel } from 'src/lib/illness-script/illness-script';
import { RichTextComponent } from '../rich-text/rich-text.component';
import { PortableTextBlock } from '@portabletext/types';
import { UnsubscribeOnDestroyAdapter } from 'src/lib/base/unsubscribe.adapter';
import { AccordionComponent } from '../accordion/accordion.component';
import { ImagesCarouselComponent } from '../images-carousel/images-carousel.component';

@Component({
  selector: 'app-single-illness',
  standalone: true,
  templateUrl: './single-illness.component.html',
  styleUrls: ['./single-illness.component.scss'],
  imports: [
    ImagesCarouselComponent,
    TextboxModule,
    AccordionComponent,
    RichTextComponent,
    CommonModule,
  ],
})
export class SingleIllnessComponent extends UnsubscribeOnDestroyAdapter {
  @Input() illness: IllnessScriptModel | null = null;

  protected readonly illnessTypes = illnessTypes;

  constructor(private location: Location) {
    super();
  }

  override ngOnDestroy(): void {
    const currentUrl = this.location.path();
    const url = new URL(window.location.origin + currentUrl);
    url.searchParams.delete('illnessScript');
    this.location.replaceState(url.pathname + url.search);
  }

  ngOnInit(): void {
    const currentUrl = this.location.path();
    const url = new URL(window.location.origin + currentUrl);

    url.searchParams.set('illnessScript', this.illness?.id || '');
    this.location.replaceState(url.pathname + url.search);
  }

  get images() {
    return this.illness?.images?.slice(0, 3) ?? [];
  }

  getRichText(illnessScriptModel: any, type: string) {
    return illnessScriptModel[type] as PortableTextBlock[];
  }
}
