import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  map,
  shareReplay,
  takeUntil,
} from 'rxjs/operators';
import { Options } from 'src/app/components/filter-generic/filter-buttons/filter-buttons.types';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { UnsubscribeOnDestroyAdapter } from 'src/lib/base/unsubscribe.adapter';
import { IllnessScriptModel } from 'src/lib/illness-script/illness-script';
import { getIllnessScript } from 'src/lib/illness-script/store/illness-script.actions';
import { selectIllnessScript } from 'src/lib/illness-script/store/illness-script.selector';
import {
  RecentSearch,
  Filter,
  ResultType,
  SearchResult,
} from 'src/lib/search/search';
import * as searchActions from 'src/lib/search/store/search.actions';
import { SearchState } from 'src/lib/search/store/search.reducer';
import {
  selectIsLoading,
  selectRecentSearches,
  selectSearchResults,
} from 'src/lib/search/store/search.selector';

@Component({
  selector: 'app-search-container',
  templateUrl: './search-container.component.html',
  styleUrls: ['./search-container.component.scss'],
})
export class SearchContainerComponent extends UnsubscribeOnDestroyAdapter {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  readonly searchResults$: Observable<SearchResult[]> =
    this.store.select(selectSearchResults);
  readonly recentSearches$: Observable<RecentSearch[]> =
    this.store.select(selectRecentSearches);
  readonly isLoading$: Observable<boolean> = this.store.select(selectIsLoading);

  private readonly searchTextSubject = new BehaviorSubject<string>('');
  readonly searchText$ = this.searchTextSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  readonly filteredRecentSearches$: Observable<RecentSearch[]>;
  readonly filteredSearchResults$: Observable<
    { key: ResultType; value: SearchResult[] }[]
  >;

  private readonly showRecentSearchesSubject = new BehaviorSubject<boolean>(
    false
  );
  readonly showRecentSearches$ = this.showRecentSearchesSubject.asObservable();

  private readonly showSearchResultsSubject = new BehaviorSubject<boolean>(
    false
  );
  readonly showSearchResults$ = this.showSearchResultsSubject.asObservable();

  private readonly searchSubmittedSubject = new BehaviorSubject<boolean>(false);
  readonly searchSubmitted$ = this.searchSubmittedSubject.asObservable();

  private readonly filterActivatedSubject = new BehaviorSubject<boolean>(false);
  readonly filterActivated$ = this.filterActivatedSubject.asObservable();

  private destroyed$ = new Subject<void>();

  visibleIllness: IllnessScriptModel | null = null;
  searchResultTotal = 0;
  currentSearch = '';

  readonly filters: Filter[] = [
    { name: 'Problems', value: 'problems', checked: false },
    { name: 'Illness Scripts', value: 'illnessscripts', checked: false },
  ];
  private readonly filtersSubject = new BehaviorSubject(this.filters);
  readonly filters$ = this.filtersSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  readonly sortOptions: Options[] = [
    { label: 'Sort by', value: 'sort', disabled: true },
    { label: 'Alphabetical', value: 'az', disabled: false },
    { label: 'Relevance', value: 'relevance', disabled: false },
  ];
  selectedSortOption: Options = this.sortOptions[0];
  private readonly sortOptionSubject = new BehaviorSubject<Options>(
    this.selectedSortOption
  );
  readonly sortOption$ = this.sortOptionSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private typeCounts$: Observable<Map<string, number>>;

  readonly itemsPerPageMap: Record<ResultType, number> = {
    Problems: 5,
    'Illness Scripts': 10,
  };

  readonly loadMoreMap: Record<ResultType, number> = {
    Problems: 5,
    'Illness Scripts': 5,
  };

  readonly displayCountSubject = new BehaviorSubject<
    Record<ResultType, number>
  >({
    Problems: this.itemsPerPageMap['Problems'],
    'Illness Scripts': this.itemsPerPageMap['Illness Scripts'],
  });
  readonly displayCount$ = this.displayCountSubject.asObservable();

  constructor(
    private readonly store: Store<{ search: SearchState }>,
    private readonly router: Router,
    private cdr: ChangeDetectorRef
  ) {
    super();

    this.filteredRecentSearches$ = this.getFilteredRecentSearches();
    this.filteredSearchResults$ = this.getFilteredSearchResults();
    this.typeCounts$ = this.getTypeCounts();

    this.initSearchStateSubscription();
  }

  ngAfterViewInit() {
    this.clearSearch();
    this.cdr.detectChanges();
  }

  clearSearch() {
    this.store.dispatch(searchActions.loadRecentSearches());
    this.updateSearchStates(true, false);
    this.showSearchResults$.subscribe((showSearchResults) => {
      if (showSearchResults) {
        setTimeout(() => {
          this.setInitialSortOption();
        }, 300);
      }
    });
  }

  override ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    super.ngOnDestroy();
  }

  onSearch(event: Event): void {
    const input = (event.target as HTMLFormElement).querySelector(
      'input'
    ) as HTMLInputElement;
    this.store.dispatch(
      searchActions.saveRecentSearch({ searchText: input.value })
    );
    this.store.dispatch(searchActions.loadRecentSearches());
    this.submitSearch(input.value);
  }

  submitSearch(search: string): void {
    this.store.dispatch(searchActions.clearSearchResults());
    this.searchTextSubject.next(search);

    this.sortOptionSubject.next(this.sortOptions[0]);
    this.filterActivatedSubject.next(false);

    this.displayCountSubject.next({
      Problems: this.itemsPerPageMap['Problems'],
      'Illness Scripts': this.itemsPerPageMap['Illness Scripts'],
    });

    const resetFilters = this.filters.map((filter) => ({
      ...filter,
      checked: false,
    }));
    this.filtersSubject.next(resetFilters);

    this.store.dispatch(searchActions.search({ searchText: search }));
    this.searchSubmittedSubject.next(true);
  }

  onClearSearchHistory(): void {
    this.store.dispatch(searchActions.clearSearchHistory());
  }

  onSearchTextChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.searchTextSubject.next(target.value);
    this.searchSubmittedSubject.next(false);
  }

  onFilterChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    const filterValue = target.value;
    const isChecked = target.checked;

    this.filtersSubject.next(
      this.filtersSubject
        .getValue()
        .map((filter) =>
          filter.value === filterValue
            ? { ...filter, checked: isChecked }
            : filter
        )
    );

    this.filterActivatedSubject.next(true);
  }

  onSortChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.selectedSortOption = this.sortOptions.find(
      (option) => option.value === target.value
    ) as Options;
    this.sortOptionSubject.next(this.selectedSortOption);
  }

  getCountForType(type: string): number {
    let count = 0;
    this.searchResults$
      .pipe(
        map(
          (results) => results.filter((result) => result.type === type).length
        ),
        takeUntil(this.destroyed$)
      )
      .subscribe((length) => (count = length));
    return count;
  }

  openResult(id: string, type: string): void {
    if (type === 'Problems') {
      this.router.navigate([id]);
    } else if (type === 'Illness Scripts') {
      this.store.dispatch(getIllnessScript({ id }));
      this.store
        .select(selectIllnessScript(id))
        .pipe(takeUntil(this.destroyed$))
        .subscribe((illness) => {
          this.visibleIllness = illness;
          this.modal.showModal();
        });
    }
  }

  setInitialSortOption(): void {
    const selectElement = document.getElementById(
      'sort-select'
    ) as HTMLSelectElement;

    if (!selectElement) {
      return;
    }

    selectElement.value = this.sortOptions[2].value;
    this.selectedSortOption = this.sortOptions[2];
    this.sortOptionSubject.next(this.selectedSortOption);
  }

  loadMore(type: ResultType): void {
    const currentCounts = this.displayCountSubject.getValue();
    const totalCount = this.getCountForType(type);
    if (!this.hasMoreToLoad(type, totalCount)) {
      return;
    }

    this.displayCountSubject.next({
      ...currentCounts,
      [type]: currentCounts[type] + this.loadMoreMap[type],
    });
  }

  hasMoreToLoad(type: ResultType, totalCount: number): boolean {
    const currentCounts = this.displayCountSubject.getValue();
    return currentCounts[type] < totalCount;
  }

  removeRecentSearch(recentSearch: string): void {
    this.store.dispatch(searchActions.removeRecentSearch({ recentSearch }));
    this.store.dispatch(searchActions.loadRecentSearches());
  }

  private getFilteredRecentSearches(): Observable<RecentSearch[]> {
    return combineLatest([this.recentSearches$, this.searchText$]).pipe(
      map(([recentSearches, searchText]) =>
        searchText
          ? recentSearches.filter((search) =>
              search.title.toLowerCase().startsWith(searchText.toLowerCase())
            )
          : recentSearches
      ),
      shareReplay(1)
    );
  }

  private getFilteredSearchResults(): Observable<
    { key: ResultType; value: SearchResult[] }[]
  > {
    return combineLatest([
      this.searchResults$,
      this.filters$,
      this.sortOption$,
      this.filterActivated$,
      this.displayCount$,
    ]).pipe(
      map(
        ([
          searchResults,
          filters,
          sortOption,
          filterActivated,
          displayCount,
        ]) => {
          let filteredResults = searchResults;

          if (filterActivated) {
            const activeFilters = filters
              .filter((filter) => filter.checked)
              .map((filter) => filter.name);

            if (activeFilters.length > 0) {
              filteredResults = searchResults.filter((result) =>
                activeFilters.includes(result.type)
              );
            }
          }

          const allPossibleGroups = filters.map((filter) => filter.name);
          const groupedResults = this.groupResultsByType(
            filteredResults,
            allPossibleGroups
          );
          const sortedGroupedResults = this.sortGroupedResults(
            groupedResults,

            sortOption,
            allPossibleGroups
          );

          return sortedGroupedResults.map((group) => ({
            key: group.key as ResultType,
            value: group.value.slice(0, displayCount[group.key as ResultType]),
          }));
        }
      ),
      shareReplay(1)
    );
  }

  private getTypeCounts(): Observable<Map<string, number>> {
    return this.searchResults$.pipe(
      map((results) => {
        const counts = new Map<string, number>();
        results.forEach((result) => {
          counts.set(result.type, (counts.get(result.type) || 0) + 1);
        });
        return counts;
      }),
      shareReplay(1)
    );
  }

  private groupResultsByType(
    results: SearchResult[],
    allPossibleGroups: string[]
  ): { [key: string]: SearchResult[] } {
    const groupedResults: { [key: string]: SearchResult[] } = {};

    allPossibleGroups.forEach((group) => {
      groupedResults[group] = [];
    });

    results.forEach((result) => {
      if (!groupedResults[result.type]) {
        groupedResults[result.type] = [];
      }
      groupedResults[result.type].push(result);
    });

    return groupedResults;
  }

  private sortGroupedResults(
    groupedResults: { [key: string]: SearchResult[] },
    sortOption: Options,
    allPossibleGroups: string[]
  ): { key: string; value: SearchResult[] }[] {
    const sortedResults = allPossibleGroups.map((key) => ({
      key,
      value: groupedResults[key] || [],
    }));

    if (sortOption.value === 'az') {
      sortedResults.forEach((group) => {
        group.value.sort((a, b) => a.title.localeCompare(b.title));
      });
    }

    return sortedResults;
  }

  private initSearchStateSubscription(): void {
    this.subs.sink = combineLatest([
      this.searchResults$,
      this.searchText$,
      this.filteredRecentSearches$,
      this.searchSubmitted$,
    ]).subscribe(([results, searchText, recentSearches, searchSubmitted]) => {
      this.searchResultTotal = results.length;
      this.updateSearchStates(
        !searchSubmitted && recentSearches.length > 0,
        searchSubmitted
      );
      this.currentSearch = searchText;
    });
  }

  private updateSearchStates(
    recentSearches: boolean,
    searchResults: boolean
  ): void {
    this.showRecentSearchesSubject.next(recentSearches);
    this.showSearchResultsSubject.next(searchResults);
  }

  trackByKey(
    index: number,
    item: { key: ResultType; value: SearchResult[] }
  ): ResultType {
    return item.key;
  }

  trackById(index: number, item: SearchResult): string {
    return item.id;
  }

  trackByFilter(index: number, item: Filter): string {
    return item.value;
  }

  buildSectionId(groupKey = '') {
    if (!groupKey) return '';
    return `${groupKey.toLowerCase().replaceAll(' ', '-')}-title`;
  }
}
