import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { DataService } from '../mock-integration/data/data.service';
import { BaseNode, ContentNode } from '../mock-integration/data/data.model';
import { DDxTableColumn, DDxTableModel } from './ddx-table';
import { DiagnosesType } from '../illness-script/illness-script';

@Injectable({
  providedIn: 'root',
})
export class DDxTableService {
  constructor(private dataService: DataService) {}

  get(
    problemId: string,
    stepId: string,
    ddxId: string
  ): Observable<DDxTableModel[]> {
    return this.dataService.getIllnessScriptsByDDxId(problemId, ddxId).pipe(
      switchMap((illnessScripts) => {
        if (illnessScripts.length === 0) {
          return of([]);
        }
        return forkJoin(
          illnessScripts.map((illnessScript) =>
            of(this.mapIllnessScriptToDDxTableModel(ddxId, illnessScript))
          )
        );
      }),
      catchError((error) => {
        console.error('Error in DDxTableService:', error);
        return of([]);
      })
    );
  }

  private mapIllnessScriptToDDxTableModel(
    ddxId: string,
    illnessScript: BaseNode
  ): DDxTableModel {
    const contentNodes: ContentNode[] = illnessScript.content;

    return {
      id: ddxId,
      illnessId: illnessScript.id,
      illnessName: illnessScript.title,
      riskFactors: this.dataService.mapContentNodesToString(
        contentNodes,
        DDxTableColumn.RiskFactors
      ),
      symptoms: this.dataService.mapContentNodesToString(
        contentNodes,
        DDxTableColumn.Symptoms
      ),
      signs: this.dataService.mapContentNodesToString(
        contentNodes,
        DDxTableColumn.Signs
      ),
      emergent: this.dataService.mapContentNodesToBoolean(
        contentNodes,
        DDxTableColumn.DiagnosesType,
        DiagnosesType.Emergent
      ),
      mustNotMiss: this.dataService.mapContentNodesToBoolean(
        contentNodes,
        DDxTableColumn.DiagnosesType,
        DiagnosesType.MustNotMiss
      ),
      workupContent: this.dataService.mapContentNodesToString(
        contentNodes,
        DDxTableColumn.WorkUp
      ),
    };
  }
}
