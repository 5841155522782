import { createAction, props } from '@ngrx/store';
import { DDxTableModel } from '../ddx-table';

export const getDDxTable = createAction(
  '[DDxTable] Get DDx table',
  props<{ problemId: string; stepId: string; ddxId: string }>()
);

export const getDDxTableSuccess = createAction(
  '[DDxTable] Get DDx table Success',
  props<{ ddxTable: DDxTableModel[] }>()
);

export const getDDxTableFailure = createAction(
  '[DDxTable] Get DDx table Failure',
  props<{ error: any }>()
);
