import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  DiagnosesType,
  IllnessScriptModel,
  IllnessScriptNodeTemplate,
} from './illness-script';
import { map, catchError, switchMap } from 'rxjs/operators';
import { DataService } from '../mock-integration/data/data.service';
import { BaseNode } from '../mock-integration/data/data.model';
import { TaxonomyService } from '../mock-integration/taxonomy/taxonomy.service';
import { TaxonomyModel } from '../mock-integration/taxonomy/taxonomy.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class IllnessScriptService {
  constructor(
    private taxonomyService: TaxonomyService,
    private dataService: DataService,
    private http: HttpClient
  ) {}

  get(): Observable<IllnessScriptModel[]> {
    return this.taxonomyService
      .getTaxonomyByClassification('', 'disease', 0, 200)
      .pipe(
        map((illnessScripts) =>
          this.mapTaxonomyModelToIllnessScriptModel(illnessScripts)
        )
      );
  }

  getById(id: string): Observable<IllnessScriptModel> {
    return this.http
      .get<
        { id: string; slug: string }[]
      >(`${environment.fileBaseUrl}/illness_scripts.json`)
      .pipe(
        map((illnessScripts) => {
          const illnessScript = illnessScripts.find(
            (script) => script.id === id
          );
          if (!illnessScript) {
            throw new Error(`IllnessScript with id ${id} not found`);
          }
          return illnessScript;
        }),
        switchMap((illnessScript) =>
          this.http.get<BaseNode>(
            `${environment.fileBaseUrl}/illness_script/${illnessScript.slug}.json`
          )
        ),
        map((nodeData) => this.mapNodeToIllnessScriptModel(nodeData)),
        catchError((error: any) => {
          console.error('Error fetching illness script:', error);
          return of();
        })
      );
  }

  getByProblemId(problemId: string): Observable<IllnessScriptModel[]> {
    return this.dataService
      .getIllnessScriptsByProblemId(problemId)
      .pipe(
        map((illnessScripts: BaseNode[]) =>
          illnessScripts.map((illnessScript) =>
            this.mapNodeToIllnessScriptModel(illnessScript)
          )
        )
      );
  }

  getByStepId(
    problemId: string,
    stepId: string
  ): Observable<IllnessScriptModel[]> {
    return this.dataService
      .getIllnessScriptsByStepId(problemId, stepId)
      .pipe(
        map((illnessScripts: BaseNode[]) =>
          illnessScripts.map((illnessScript) =>
            this.mapNodeToIllnessScriptModel(illnessScript)
          )
        )
      );
  }

  getByDDxId(
    problemId: string,
    stepId: string,
    ddxId: string
  ): Observable<IllnessScriptModel[]> {
    return this.dataService
      .getIllnessScriptsByDDxId(problemId, ddxId)
      .pipe(
        map((illnessScripts: BaseNode[]) =>
          illnessScripts.map((illnessScript) =>
            this.mapNodeToIllnessScriptModel(illnessScript)
          )
        )
      );
  }

  private mapTaxonomyModelToIllnessScriptModel(
    illnessScript: TaxonomyModel[]
  ): IllnessScriptModel[] {
    return illnessScript.map((illnessScript) => {
      return {
        id: illnessScript.id,
        illnessName: illnessScript.title,
        overview: '',
        classicPresentation: '',
        uncommonPresentation: '',
        pathophysiology: '',
        emergent: false,
        mustNotMiss: false,
        frequency: '',
        riskFactors: '',
        symptoms: '',
        signs: '',
        timeCourse: '',
        workup: '',
        management: '',
        pearlsAndPitfalls: '',
        ruledOut: false,
        images: [],
      } as IllnessScriptModel;
    });
  }

  private mapNodeToIllnessScriptModel(
    illnessScriptNone: BaseNode
  ): IllnessScriptModel {
    return {
      id: illnessScriptNone.id,
      illnessName: illnessScriptNone.title,
      overview: this.dataService.mapContentNodesToString(
        illnessScriptNone.content,
        IllnessScriptNodeTemplate.Summary
      ),
      classicPresentation: this.dataService.mapContentNodesToString(
        illnessScriptNone.content,
        IllnessScriptNodeTemplate.ClassicPresentation
      ),
      uncommonPresentation: this.dataService.mapContentNodesToString(
        illnessScriptNone.content,
        IllnessScriptNodeTemplate.UncommonPresentation
      ),
      pathophysiology: this.dataService.mapContentNodesToString(
        illnessScriptNone.content,
        IllnessScriptNodeTemplate.Pathophysiology
      ),
      emergent: this.dataService.mapContentNodesToBoolean(
        illnessScriptNone.content,
        IllnessScriptNodeTemplate.DiagnosesType,
        DiagnosesType.Emergent
      ),
      mustNotMiss: this.dataService.mapContentNodesToBoolean(
        illnessScriptNone.content,
        IllnessScriptNodeTemplate.DiagnosesType,
        DiagnosesType.MustNotMiss
      ),
      frequency: this.dataService.mapContentNodesToString(
        illnessScriptNone.content,
        IllnessScriptNodeTemplate.Frequency
      ),
      riskFactors: this.dataService.mapContentNodesToString(
        illnessScriptNone.content,
        IllnessScriptNodeTemplate.EtiologyRiskFactors
      ),
      symptoms: this.dataService.mapContentNodesToString(
        illnessScriptNone.content,
        IllnessScriptNodeTemplate.SymptomsAll
      ),
      signs: this.dataService.mapContentNodesToString(
        illnessScriptNone.content,
        IllnessScriptNodeTemplate.SignsAll
      ),
      timeCourse: this.dataService.mapContentNodesToString(
        illnessScriptNone.content,
        IllnessScriptNodeTemplate.TimeCourse
      ),
      workup: this.dataService.mapContentNodesToString(
        illnessScriptNone.content,
        IllnessScriptNodeTemplate.WorkUp
      ),
      management: this.dataService.mapContentNodesToString(
        illnessScriptNone.content,
        IllnessScriptNodeTemplate.FundamentalsOfManagement
      ),
      pearlsAndPitfalls: this.dataService.mapContentNodesToString(
        illnessScriptNone.content,
        IllnessScriptNodeTemplate.PearlsAndPitfalls
      ),
      ruledOut: false,
      images: [],
    } as IllnessScriptModel;
  }
}
