import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkingDDxTableComponent } from './working-ddx-table.component';
import { IconChipsComponent } from '../icon-chips/icon-chips.component';
import { SvgComponent } from '../svg/svg.component';
import { LinkComponent } from '../link/link.component';
import { ListItemComponent } from '../list-item/list-item.component';
import { SingleIllnessComponent } from '../single-illness/single-illness.component';
import ButtonComponent from '../button/button.component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { IllnessScriptService } from 'src/lib/illness-script/illness-script.service';
import { IllnessScriptListEffect } from 'src/lib/illness-script/store/illness-script.effect';
import { illnessScriptListReducer } from 'src/lib/illness-script/store/illness-script.reducer';
import { illnessScriptFeatureKey } from 'src/lib/illness-script/store/illness-script.selector';
import { ddxTableFeatureKey } from 'src/lib/ddx-table/store/ddx-table.selectors';
import { RichTextComponent } from '../rich-text/rich-text.component';
import { ddxTableReducer } from 'src/lib/ddx-table/store/ddx-table.reducer';
import { StepCTAComponent } from '../../step/components/step-cta/step-cta.component';
import { ModalComponent } from '../modal/modal.component';
import { CheckboxButtonComponent } from '../checkbox-button/checkbox-button.component';
import { DDxTableEffects } from 'src/lib/ddx-table/store/ddx-table.effects';
import { DDxTableService } from 'src/lib/ddx-table/ddx-table.service';
import { SpinnerLoaderComponent } from '../spinner-loader/spinner-loader.component';
import { ScreenService } from 'src/lib/screen/screen.service';

@NgModule({
  declarations: [WorkingDDxTableComponent],
  providers: [DDxTableService, IllnessScriptService, ScreenService],
  imports: [
    CommonModule,
    SvgComponent,
    LinkComponent,
    ListItemComponent,
    SingleIllnessComponent,
    SpinnerLoaderComponent,
    ButtonComponent,
    IconChipsComponent,
    StoreModule.forFeature(ddxTableFeatureKey, ddxTableReducer),
    EffectsModule.forFeature([DDxTableEffects]),
    StoreModule.forFeature(illnessScriptFeatureKey, illnessScriptListReducer),
    EffectsModule.forFeature([IllnessScriptListEffect]),
    RichTextComponent,
    StepCTAComponent,
    ModalComponent,
    CheckboxButtonComponent,
  ],
  exports: [WorkingDDxTableComponent],
})
export class WorkingDDxTableModule {}
