<div class="header">
  <!-- Mobile back button (only shown when not on home page) -->
  <button *ngIf="!isHomePage" type="button" class="mh-icon d-sm-flex d-lg-none"
    aria-label="Back to Clinical Reasoning Dashboard"
    (click)="isClinicalReasoning ? redirectToPreviousStep() : redirectToHomePage();">
    <app-svg svg="previous-24" fill="#FFFFFF" aria-hidden="true" />
  </button>

  <!-- Desktop logo button (always shown on desktop, shown on mobile only when on home page) -->
  <button type="button" class="mh-icon m-width d-flex align-items-center" *ngIf="isHomePage || !isMobile"
    aria-label="Clinical Reasoning Dashboard Home" (click)="redirectToHomePage()">
    <img src="../../../assets/mh-logo.svg" alt="" aria-hidden="true" class="logo">
    <h1 class="title text-wrap mb-0">Clinical Reasoning</h1>
  </button>

  <div class="d-flex align-items-center flex-grow-1 justify-content-start">
    <nav class="tabs d-none d-lg-flex align-items-center">
      <div class="dropdown">
        <button type="button" class="my-account d-flex align-items-center" (click)="toggleDropdown()"
          [attr.aria-expanded]="openDropdown" aria-controls="account-dropdown">
          <img src="../../../../../assets/my-account-icon.svg" alt="" aria-hidden="true" class="icon">
          <span class="home-text mb-0">My Account</span>
          <img src="../../../../../assets/chevron-up-white.svg" alt="" aria-hidden="true" class="chevron-icon"
            [class.rotate]="!openDropdown">
        </button>
        <div id="account-dropdown" class="account-options" [class.d-none]="!openDropdown"
          [class.dropdown-animation]="!openDropdown" role="menu" aria-label="Account options">
          <h2 class="options-title">My Account</h2>
          <button class="options" (click)="openAccountDetails()" role="menuitem">
            My Account Details
          </button>
          <button class="options" (click)="signOut()" role="menuitem">
            Sign Out
          </button>
        </div>
      </div>
    </nav>
  </div>

  <app-search *ngIf="isSearchComponentEnabled$ | async" class="search-component" />

  <div class="menu d-sm-inline d-lg-none">
    <div class="d-flex align-items-center">
      <button class="hamburger-button" type="button" (click)='showModal = true' aria-label="Open menu"
        [attr.aria-expanded]="showModal">
        <img src="../../../../../assets/hamburguer.svg" alt="" aria-hidden="true">
      </button>
    </div>
  </div>
</div>

<app-menu-drawer-mobile icon="/assets/close-white.svg" (close)='showModal = false' *ngIf='showModal' />