export const emergentIconChips = {
  svg: 'medical-icon',
  color: '#DA1921',
  label: 'Emergent',
  description: 'Indicates Emergent diagnosis',
  hideLabel: true,
};

export const mustNotMissIconChips = {
  svg: 'alert-triangle',
  color: '#A23E2E',
  label: 'Must Not Miss',
  description: 'Indicates Must Not Miss diagnosis',
  hideLabel: true,
};

export const arrowIconChips = {
  svg: 'chevron-right',
  color: '#083377',
  label: 'Open illness script',
  description: 'Indicates CTA to open illness script',
  hideLabel: true,
};

export const emergentWithLabelIconChips = {
  svg: 'medical-icon',
  color: '#DA1921',
  label: 'Emergent',
  description: 'Indicates Emergent diagnosis',
  hideLabel: false,
};

export const mustNotMissWithLabelIconChips = {
  svg: 'alert-triangle',
  color: '#A23E2E',
  label: 'Must Not Miss',
  description: 'Indicates Must Not Miss diagnosis',
  hideLabel: false,
};

export const DiagnosesIconChips = [
  emergentWithLabelIconChips,
  mustNotMissWithLabelIconChips,
];
