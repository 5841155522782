import {
  ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageViewModel } from './images-carousel.types';
import { ModalComponent } from '../modal/modal.component';
import PinchZoom from 'pinch-zoom-js';

@Component({
  selector: 'app-images-carousel',
  standalone: true,
  imports: [CommonModule, ModalComponent],
  templateUrl: './images-carousel.component.html',
  styleUrls: ['./images-carousel.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ImagesCarouselComponent {
  @ViewChild(ModalComponent) modal!: ModalComponent;
  @Input() mode: 'horizontal' | 'grid' = 'horizontal';
  imagesLoaded = 0;
  @Input() size: 'normal' | 'small' = 'normal';
  @Input() images: ImageViewModel[] = [];
  lastFocusedElement!: HTMLElement | null;
  @Output() imagesLoadedEmitter = new EventEmitter<number>();
  activeImage: ImageViewModel | undefined;
  detailIndex = 1;
  showDetail = false;
  zoomInstances: PinchZoom[] = [];

  constructor(private changeDetector: ChangeDetectorRef) {}

  onImageLoad() {
    this.imagesLoaded += 1;
    this.imagesLoadedEmitter.emit(this.imagesLoaded);
  }

  onKeyDown(event: KeyboardEvent, image: ImageViewModel, index: number) {
    this.lastFocusedElement = document.activeElement as HTMLElement;
    if (event.key === ' ' || event.key === 'Enter') {
      this.setActiveImage(image, index);
    }
  }

  onLoadActiveImage() {
    this.modal.showModal();
  }

  setActiveImage(image: ImageViewModel, index?: number) {
    if (index) this.detailIndex = index + 1;
    this.activeImage = image;
  }

  navigateImage(offset: number) {
    let newIndex = this.detailIndex + offset;

    if (newIndex < 1) newIndex = this.images.length;
    if (newIndex > this.images.length) newIndex = 1;

    this.detailIndex = newIndex;
    this.setActiveImage(this.images[this.detailIndex - 1]);
  }

  closeDetail() {
    this.zoomInstances.forEach((i) => {
      i.destroy();
    });

    this.showDetail = false;
    this.changeDetector.detectChanges();
    this.lastFocusedElement?.focus();
  }
}
