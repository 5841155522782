import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IllnessScriptListState } from './illness-script.reducer';

export const illnessScriptFeatureKey = 'illnessScript';
export const selectedIllnessScriptFeatureKey = 'selectedIllnessScript';

export const selectIllnessScriptState =
  createFeatureSelector<IllnessScriptListState>(illnessScriptFeatureKey);

export const selectAllIllnessScripts = () =>
  createSelector(selectIllnessScriptState, (state) =>
    Object.values(state.entities)
  );

export const selectIllnessScript = (id: string) =>
  createSelector(selectIllnessScriptState, (state) => state.entities[id]);

export const selectIllnessScripts = (ids: string[]) =>
  createSelector(selectIllnessScriptState, (state) => {
    return Object.values(state.entities).filter((illness) =>
      ids.includes(illness.id)
    );
  });

export const selectProblemIllnessScripts = (problemId: string) =>
  createSelector(selectIllnessScriptState, (state) => {
    return state.groupedIllnessScripts[problemId];
  });

export const selectStepIllnessScripts = (stepId: string) =>
  createSelector(
    selectIllnessScriptState,
    (state) => state.groupedIllnessScripts[stepId]
  );

export const selectDDxIllnessScripts = (ddxId: string) =>
  createSelector(
    selectIllnessScriptState,
    (state) => state.groupedIllnessScripts[ddxId]
  );
