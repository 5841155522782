import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  arrowIconChips,
  DiagnosesIconChips,
  emergentIconChips,
  emergentWithLabelIconChips,
  mustNotMissIconChips,
  mustNotMissWithLabelIconChips,
} from '../icon-chips/icon-chips.constants';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Input } from '@angular/core';
import {
  BehaviorSubject,
  distinctUntilChanged,
  first,
  Observable,
  Subject,
  takeUntil,
} from 'rxjs';
import { UnsubscribeOnDestroyAdapter } from 'src/lib/base/unsubscribe.adapter';
import { IllnessScriptModel } from 'src/lib/illness-script/illness-script';
import { selectIllnessScript } from 'src/lib/illness-script/store/illness-script.selector';
import { getDDxTable } from 'src/lib/ddx-table/store/ddx-table.actions';
import { ddxTableState } from 'src/lib/ddx-table/store/ddx-table.reducer';
import { selectDDxTable } from 'src/lib/ddx-table/store/ddx-table.selectors';
import { getIllnessScript } from 'src/lib/illness-script/store/illness-script.actions';
import { Filter } from 'src/lib/search/search';
import { setCurrentTable } from 'src/lib/step/store/step.actions';
import { ModalComponent } from '../modal/modal.component';
import { DDxTableModel } from 'src/lib/ddx-table/ddx-table';
import { RadioOptions } from '../radio-group/RadioOptionsModel';
import { StepType } from 'src/lib/step/step';
import { StepContentService } from 'src/lib/step/step-content.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ScreenService } from 'src/lib/screen/screen.service';

@Component({
  selector: 'app-working-ddx-table',
  templateUrl: './working-ddx-table.component.html',
  styleUrls: ['./working-ddx-table.component.scss'],
})
export class WorkingDDxTableComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit
{
  constructor(
    private readonly store: Store<{ workingDDx: ddxTableState }>,
    protected sanitizer: DomSanitizer,
    private stepContentService: StepContentService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private screenService: ScreenService
  ) {
    super();
  }

  @ViewChildren('tableContainer') tableContainers!: QueryList<ElementRef>;
  @ViewChild(ModalComponent) modal!: ModalComponent;

  @HostBinding('class.high-zoom') isHighZoom = false;
  @HostBinding('class.very-high-zoom') isVeryHighZoom = false;

  @Input() problemId!: string;
  @Input() stepId!: string;
  @Input() ddxId!: string;
  @Input() selectedModal: 'workup' | 'illness' | undefined = undefined;
  @Output() continueCTA: EventEmitter<number> = new EventEmitter();
  @Output() backCTA: EventEmitter<number> = new EventEmitter();

  ddxTable$!: Observable<DDxTableModel[]>;
  ddxTableList!: DDxTableModel[];
  visibleIllness: IllnessScriptModel | null = null;

  private destroyed$ = new Subject<void>();

  iconChips = DiagnosesIconChips;
  emergentIcon = emergentIconChips;
  mustNotMissIcon = mustNotMissIconChips;
  emergentWitLabelIcon = emergentWithLabelIconChips;
  mustNotMissWitLabelIcon = mustNotMissWithLabelIconChips;
  arrowIcon = arrowIconChips;
  totalDDx = 0;

  workupData = {
    illnessName: '',
    emergent: false,
    mustNotMiss: false,
    info: '',
  };

  readonly StepType = StepType;
  options$: Observable<RadioOptions[]> = this.stepContentService.options$;

  get modalTitle() {
    return this.visibleIllness && this.selectedModal === 'illness'
      ? 'Illness Script'
      : 'Workup';
  }

  readonly filters: Filter[] = [
    { name: 'Risk factors', value: 'risks', checked: true },
    { name: 'Associated symptoms', value: 'symptoms', checked: true },
    { name: 'Signs', value: 'signs', checked: true },
  ];
  readonly filtersSubject = new BehaviorSubject(this.filters);
  readonly filters$ = this.filtersSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  ngOnInit(): void {
    this.store.dispatch(setCurrentTable({ id: this.ddxId }));
    this.loadDdx();

    this.screenService.getScreenState().subscribe((state) => {
      this.isHighZoom = state.isHighZoom && !state.isVeryHighZoom;
      this.isVeryHighZoom = state.isVeryHighZoom;
    });
  }

  ngAfterViewInit(): void {
    this.loadDdx();

    setTimeout(() => {
      this.setupScrollFades();
    });
  }

  override ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.destroyed$.next();
    this.destroyed$.complete();
    super.ngOnDestroy();
  }

  private setupScrollFades() {
    if (!this.tableContainers || this.tableContainers.length === 0) {
      return;
    }

    this.tableContainers.forEach((tableContainerRef) => {
      const container = tableContainerRef.nativeElement;
      const wrapper = container.closest('.table-wrapper');

      if (!wrapper) {
        return;
      }

      this.updateFades(container, wrapper);

      const scrollHandler = () => {
        this.updateFades(container, wrapper);
      };

      const resizeHandler = () => {
        this.updateFades(container, wrapper);
      };

      container.addEventListener('scroll', scrollHandler);
      window.addEventListener('resize', resizeHandler);

      // Store cleanup functions
      this.subs.add({
        unsubscribe: () => {
          container.removeEventListener('scroll', scrollHandler);
          window.removeEventListener('resize', resizeHandler);
        },
      });
    });
  }

  private updateFades(container: HTMLElement, wrapper: HTMLElement) {
    const scrollLeft = container.scrollLeft;
    const scrollWidth = container.scrollWidth;
    const clientWidth = container.clientWidth;
    const maxScroll = scrollWidth - clientWidth;

    if (scrollLeft > 0) {
      wrapper.classList.add('show-left-fade');
    } else {
      wrapper.classList.remove('show-left-fade');
    }

    if (scrollLeft < maxScroll) {
      wrapper.classList.add('show-right-fade');
    } else {
      wrapper.classList.remove('show-right-fade');
    }
  }

  loadDdx = () => {
    this.store.dispatch(
      getDDxTable({
        problemId: this.problemId,
        stepId: this.stepId,
        ddxId: this.ddxId,
      })
    );

    this.ddxTable$ = this.store.select(selectDDxTable);

    this.ddxTable$.subscribe((data) => {
      this.totalDDx = data.length;
      this.ddxTableList = [...data].sort((a, b) =>
        a.illnessName.localeCompare(b.illnessName)
      );
    });
  };

  toSafeHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  openIllnessScript = (id: string) => {
    this.store.dispatch(getIllnessScript({ id: id }));

    this.store
      .select(selectIllnessScript(id))
      .pipe(takeUntil(this.destroyed$))
      .subscribe((illness) => {
        this.visibleIllness = illness;
        this.openModal('illness');
      });
  };

  openWorkup = (content: DDxTableModel) => {
    this.setWorkupData(content);
    this.openModal('workup');
  };

  openModal(modal: 'workup' | 'illness') {
    this.selectedModal = modal;
    this.modal.showModal();
  }

  setWorkupData = (currentDdx: DDxTableModel) => {
    this.workupData.illnessName = currentDdx.illnessName;
    this.workupData.emergent = currentDdx.emergent;
    this.workupData.mustNotMiss = currentDdx.mustNotMiss;
    this.workupData.info = currentDdx.workupContent
      ? currentDdx.workupContent
      : 'No content available';
  };

  shouldMergeRow(row: DDxTableModel): boolean {
    return !row.riskFactors && !row.symptoms && !row.signs;
  }

  onFilterChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    const filterValue = target.value;
    const isChecked = target.checked;

    this.filtersSubject.next(
      this.filtersSubject
        .getValue()
        .map((filter) =>
          filter.value === filterValue
            ? { ...filter, checked: isChecked }
            : filter
        )
    );
  }

  showSection(filter: string): boolean {
    return (
      this.filtersSubject.getValue().find((f) => f.value === filter)?.checked ??
      false
    );
  }

  onContinueClicked = () => {
    this.continueCTA.emit();
  };

  onBackClicked = () => {
    this.backCTA.emit();
  };

  onRedirect() {
    this.options$.pipe(first()).subscribe((options) => {
      this.router.navigate([options[0].path], {
        relativeTo: this.activatedRoute,
      });
    });
  }
}
