import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { UnsubscribeOnDestroyAdapter } from 'src/lib/base/unsubscribe.adapter';
import { ModalComponent } from '../modal/modal.component';
import { MOBILE_MAX_WIDTH_PIXELS } from 'src/app/step/step.constants';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit, OnDestroy
{
  @ViewChild(ModalComponent) modal!: ModalComponent;
  @ViewChild('input') input!: ElementRef;

  get isMobile(): boolean {
    return window.innerWidth <= MOBILE_MAX_WIDTH_PIXELS;
  }

  active = false;

  constructor(private router: Router) {
    super();
  }

  preventTyping(event: KeyboardEvent) {
    if (event.key !== 'Tab' && event.key !== 'Shift') {
      event.preventDefault();
      this.input.nativeElement.click();
    }
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.closeModal();
      }
    });
  }

  closeModal() {
    this.modal.close();
  }

  openModal() {
    this.active = true;
    this.modal.showModal();
  }
}
