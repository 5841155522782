import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SearchComponent } from '../search/search.component';
import { ModalComponent } from '../modal/modal.component';
import { SearchContainerComponent } from '../search/components/search-container/search-container.component';
import { ListItemComponent } from '../list-item/list-item.component';
import { searchFeatureKey } from 'src/lib/search/store/search.selector';
import { searchReducer } from 'src/lib/search/store/search.reducer';
import { SearchEffects } from 'src/lib/search/store/search.effect';
import { SearchService } from 'src/lib/search/search.service';
import { FormsModule } from '@angular/forms';
import { GroupByPipe } from 'src/pipes/group-by.pipe';
import { LinkComponent } from '../link/link.component';
import { illnessScriptFeatureKey } from 'src/lib/illness-script/store/illness-script.selector';
import { illnessScriptListReducer } from 'src/lib/illness-script/store/illness-script.reducer';
import { IllnessScriptListEffect } from 'src/lib/illness-script/store/illness-script.effect';
import { IllnessScriptService } from 'src/lib/illness-script/illness-script.service';
import { SingleIllnessComponent } from '../single-illness/single-illness.component';
import { CheckboxButtonComponent } from '../checkbox-button/checkbox-button.component';
import { SelectComponent } from '../select/select.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { ManifestationService } from 'src/lib/manifestation/manifestation.service';
import { SkeletonComponent } from './components/skeleton/skeleton.component';

@NgModule({
  declarations: [
    SearchComponent,
    SearchContainerComponent,
    GroupByPipe,
    SkeletonComponent,
  ],
  providers: [SearchService, ManifestationService, IllnessScriptService],
  exports: [SearchComponent],
  imports: [
    CommonModule,
    FormsModule,
    StoreModule.forFeature(searchFeatureKey, searchReducer),
    EffectsModule.forFeature([SearchEffects]),
    StoreModule.forFeature(illnessScriptFeatureKey, illnessScriptListReducer),
    EffectsModule.forFeature([IllnessScriptListEffect]),
    ModalComponent,
    ListItemComponent,
    LinkComponent,
    SingleIllnessComponent,
    CheckboxButtonComponent,
    SelectComponent,
    SearchInputComponent,
  ],
})
export class SearchModule {}
