import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-dynamic-heading',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './dynamic-heading.component.html',
  styleUrls: ['./dynamic-heading.component.scss'],
})
export class DynamicHeadingComponent {
  @Input() headingLevel = 'h1';
  @Input() headingText = '';
  @Input() customClass = '';
}
