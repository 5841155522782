import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getRouterSelectors } from '@ngrx/router-store';
import { RouterState } from './router.reducer';

export const routerFeatureKey = 'router';

export const selectRouterState =
  createFeatureSelector<RouterState>(routerFeatureKey);

export const selectRouterReducerState = createSelector(
  selectRouterState,
  (state: RouterState) => state.routerState
);

const {
  selectCurrentRoute,
  selectFragment,
  selectQueryParams,
  selectQueryParam,
  selectRouteParams,
  selectRouteParam,
  selectUrl,
  selectRouteData,
} = getRouterSelectors(selectRouterReducerState);

export {
  selectCurrentRoute,
  selectFragment,
  selectQueryParams,
  selectQueryParam,
  selectRouteParams,
  selectRouteParam,
  selectUrl,
  selectRouteData,
};

export const selectRouteIds = createSelector(
  selectRouterState,
  (state: RouterState) => state.routerIds
);

export const selectProblemId = createSelector(selectRouteIds, (ids) =>
  ids ? ids.problemId : null
);

export const selectStepId = createSelector(selectRouteIds, (ids) =>
  ids ? ids.stepId : null
);

export const selectDifferentialId = createSelector(selectRouteIds, (ids) =>
  ids ? ids.differentialId : null
);
