<div *ngIf="!isMobile" class="search-container d-flex align-items-center">
    <button class="search-button d-flex align-items-center w-100" (click)="openModal()" aria-label="Open search"
        aria-haspopup="true">
        <span class="search-placeholder mx-3">Search problems and illness scripts</span>
        <img src='/assets/search.svg' aria-hidden="true" class="search-icon" alt="">
    </button>
</div>

<button *ngIf="isMobile" class="btn mobile-search-icon" type="button" (click)="openModal()" aria-haspopup="true"
    aria-label="Open search">
    <img src='/assets/search.svg' aria-hidden="true" alt="">
</button>

<app-modal #modal headerText='Search' icon='/assets/close.svg' (onClose)="active=false">
    <app-search-container *ngIf="active" />
</app-modal>