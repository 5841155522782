import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-redirect',
  template: '',
})
export class RedirectComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {
    const prefix = this.router.url.includes('?') ? '&' : '?';
    if (!this.router.url.includes('ngsw-bypass=true')) {
      window.location.replace(`${this.router.url}${prefix}ngsw-bypass=true`);
    }
  }
}
