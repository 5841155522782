import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap, map, catchError } from 'rxjs/operators';
import * as RouterActions from './router.actions';
import { RouterService } from './router.service';
import { RouterNavigatedAction } from '@ngrx/router-store';
import { of } from 'rxjs';

@Injectable()
export class RouterEffects {
  navigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.go),
        tap(({ path, query: queryParams, extras }) => {
          this.router.navigate(path, { queryParams, ...extras });
        })
      ),
    { dispatch: false }
  );

  urlChanged$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RouterNavigatedAction>('@ngrx/router-store/navigated'),
      map((action) => {
        const url = action.payload.event.urlAfterRedirects;
        const ids = this.routerService.parseIdsFromUrl(url);
        return RouterActions.urlChangedSuccess({ routerIds: ids });
      }),
      catchError((error) => {
        console.error('Error parsing URL:', error);
        return of(RouterActions.urlChangedFailure({ error }));
      })
    )
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private routerService: RouterService
  ) {}
}
