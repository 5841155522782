import { Injectable } from '@angular/core';
import { RouteIds } from './router';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  parseIdsFromUrl(url: string): Partial<RouteIds> {
    const urlWithoutParams = url.split('?')[0];

    const segments = urlWithoutParams
      .split('/')
      .filter((segment) => segment.length > 0);

    const ids: Partial<RouteIds> = {};

    segments.forEach((segment, index) => {
      if (index === 0) {
        ids.problemId = segment;
      } else if (segment === 'step' && segments[index + 1]) {
        ids.stepId = segments[index + 1];
      } else if (
        (segment === 'ddx-table' || segment === 'differential') &&
        segments[index + 1]
      ) {
        ids.differentialId = segments[index + 1];
      }
    });

    const uuidRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    if (ids.problemId && !uuidRegex.test(ids.problemId)) {
      throw new Error('Invalid problemId: not a valid UUID');
    }

    return ids;
  }
}
