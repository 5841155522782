import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RadioOptions } from 'src/app/components/radio-group/RadioOptionsModel';

@Injectable({
  providedIn: 'root',
})
export class StepContentService {
  options: BehaviorSubject<RadioOptions[]> = new BehaviorSubject<
    RadioOptions[]
  >([]);
  options$: Observable<RadioOptions[]> = this.options.asObservable();

  previousPath: BehaviorSubject<string> = new BehaviorSubject<string>('');
  previousPath$: Observable<string> = this.previousPath.asObservable();

  triggeredAlgorithmDrawer: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  triggeredAlgorithmDrawer$: Observable<boolean> =
    this.triggeredAlgorithmDrawer.asObservable();
}
