import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-accordion',
  standalone: true,
  imports: [CommonModule, NgbAccordionModule],
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent {
  @ViewChild('header') header!: ElementRef;
  @Input() type: 'base' | 'red' | 'blue' | 'purple' | 'contextual' = 'base';

  @Input()
  title = 'Accordion';

  @Input()
  icon?: string;

  @Input()
  openedByDefault = false;

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ')
      this.header.nativeElement.click();
  }

  public get classes(): string[] {
    return ['accordion', `accordion--${this.type}`];
  }
}
