import { createReducer, on } from '@ngrx/store';
import { RecentSearch, SearchResult } from '../search';
import * as searchActions from './search.actions';

export interface SearchState {
  searchText: string;
  searchResults: SearchResult[];
  recentSearches: RecentSearch[];
  isLoading: boolean;
  error: any;
}

export const initialState: SearchState = {
  searchText: '',
  searchResults: [],
  recentSearches: [],
  isLoading: false,
  error: null,
};

export const searchReducer = createReducer(
  initialState,
  on(
    searchActions.search,
    (state: SearchState, { searchText }: { searchText: string }) => ({
      ...state,
      searchText,
      isLoading: true,
    })
  ),
  on(
    searchActions.searchSuccess,
    (
      state: SearchState,
      { searchResults }: { searchResults: SearchResult[] }
    ) => ({
      ...state,
      searchResults,
      isLoading: false,
    })
  ),
  on(
    searchActions.searchFailure,
    (state: SearchState, { error }: { error: any }) => ({
      ...state,
      error,
      isLoading: false,
    })
  ),
  on(searchActions.saveRecentSearch, (state: SearchState) => ({
    ...state,
    recentSearches: [...state.recentSearches],
  })),
  on(
    searchActions.loadRecentSearchesSuccess,
    (
      state: SearchState,
      { recentSearches }: { recentSearches: RecentSearch[] }
    ) => ({
      ...state,
      recentSearches,
    })
  ),
  on(searchActions.removeRecentSearch, (state: SearchState) => ({
    ...state,
    recentSearches: [...state.recentSearches],
  })),
  on(searchActions.clearSearchHistory, (state: SearchState) => ({
    ...state,
    recentSearches: [],
  })),
  on(searchActions.clearSearchResults, (state: SearchState) => ({
    ...state,
    searchResults: [],
  }))
);
