<dialog 
  #dialog  
  [class]="size === 'large' ? 'size-large' : 'size-normal'"
  (click)="handleBackdropClick($event)"
  (keydown)="onKeyDown($event)"
>
    <div class="header">
      <app-dynamic-heading
        [style.width]="'100%'" 
        *ngIf="headerText && headerText !==''" 
        [headingLevel]="headingLevel" 
        [headingText]="headerText" 
        customClass="modal-header"
      />
      <button 
        autofocus
        tabindex="0" 
        type='button' 
        id='close-btn' 
        aria-label='Click to close this modal' 
        (click)='close()'
      >
        <img alt='' src='assets/close.svg' />
      </button>
    </div>
    <div class="body">
        <h1 *ngIf='title' class="title">
          <img *ngIf='titleIcon' [src]="titleIcon" alt='modal title icon' />
          {{title}}
        </h1>
        <ng-content></ng-content>
      </div>
      <div><ng-content select="[footer]" [ngTemplateOutlet]="content.templateRef"></ng-content>
    </div>
</dialog>