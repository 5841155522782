export const illnessTypes = [
  {
    title: 'Pathophysiology',
    type: 'pathophysiology',
  },
  {
    title: 'Classic Presentation',
    type: 'classicPresentation',
  },
  {
    title: 'Uncommon Presentation',
    type: 'uncommonPresentation',
  },
  {
    title: 'Frequency',
    type: 'frequency',
  },
  {
    title: 'Etiology/Risk Factors',
    type: 'riskFactors',
  },
  {
    title: 'Symptoms All',
    type: 'symptoms',
  },
  {
    title: 'Signs All',
    type: 'signs',
  },
  {
    title: 'Time Course',
    type: 'timeCourse',
  },
  {
    title: 'Work-up',
    type: 'workup',
  },
  {
    title: 'Fundamentals of Management',
    type: 'management',
  },
];
