import { createReducer, on } from '@ngrx/store';
import {
  getAllIllnessScriptsSuccess,
  getIllnessScriptFailure,
  getIllnessScriptsByDDxIdFailure,
  getIllnessScriptsByDDxIdSuccess,
  getIllnessScriptsByProblemIdFailure,
  getIllnessScriptsByProblemIdSuccess,
  getIllnessScriptsByStepIdFailure,
  getIllnessScriptsByStepIdSuccess,
  getIllnessScriptSuccess,
  resetSelectedIllnessScript,
} from './illness-script.actions';
import { IllnessScriptModel } from '../illness-script';

export interface IllnessScriptListState {
  entities: { [id: string]: IllnessScriptModel };
  groupedIllnessScripts: { [problemId: string]: IllnessScriptModel[] };
  loading: boolean;
  error: any;
}

export const initialState: IllnessScriptListState = {
  entities: {},
  groupedIllnessScripts: {},
  loading: false,
  error: null,
};

export const illnessScriptListReducer = createReducer(
  initialState,
  on(getAllIllnessScriptsSuccess, (state, { data }) => ({
    ...state,
    entities: data.reduce(
      (acc, illnessScript) => ({
        ...acc,
        [illnessScript.id]: illnessScript,
      }),
      state.entities
    ),
  })),
  on(getIllnessScriptFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  on(getIllnessScriptSuccess, (state, { data }) => ({
    ...state,
    entities: {
      ...state.entities,
      [data.id]: data,
    },
  })),
  on(getIllnessScriptFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  on(getIllnessScriptsByProblemIdSuccess, (state, { problemId, data }) => ({
    ...state,
    groupedIllnessScripts: {
      [problemId]: data,
    },
  })),
  on(getIllnessScriptsByProblemIdFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  on(getIllnessScriptsByStepIdSuccess, (state, { stepId, data }) => ({
    ...state,
    groupedIllnessScripts: {
      [stepId]: data,
    },
  })),
  on(getIllnessScriptsByStepIdFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  on(getIllnessScriptsByDDxIdSuccess, (state, { ddxId, data }) => ({
    ...state,
    groupedIllnessScripts: {
      [ddxId]: data,
    },
  })),
  on(getIllnessScriptsByDDxIdFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  on(resetSelectedIllnessScript, (state) => ({
    ...state,
    illnessScript: null,
  }))
);
