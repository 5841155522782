import { createAction, props } from '@ngrx/store';
import { RecentSearch, SearchResult } from '../search';

export const search = createAction(
  '[Search] Search',
  props<{ searchText: string }>()
);

export const searchSuccess = createAction(
  '[Search] Search Success',
  props<{ searchResults: SearchResult[] }>()
);

export const searchFailure = createAction(
  '[Search] Search Failure',
  props<{ error: any }>()
);

export const saveRecentSearch = createAction(
  '[Search] Save Recent Search',
  props<{ searchText: string }>()
);

export const loadRecentSearches = createAction('[Search] Load Recent Searches');

export const loadRecentSearchesSuccess = createAction(
  '[Search] Load Recent Searches Success',
  props<{ recentSearches: RecentSearch[] }>()
);

export const removeRecentSearch = createAction(
  '[Search] Remove Recent Search',
  props<{ recentSearch: string }>()
);

export const clearSearchHistory = createAction('[Search] Clear Search History');

export const clearSearchResults = createAction('[Search] Clear Search Results');
