import { createAction, props } from '@ngrx/store';
import { IllnessScriptModel } from '../illness-script';

export const getAllIllnessScripts = createAction(
  '[Illness Script List API] Invoke Illness Script List API'
);

export const getAllIllnessScriptsSuccess = createAction(
  '[Illness Script List API] Fetch API Success',
  props<{ data: IllnessScriptModel[] }>()
);

export const getAllIllnessScriptsFailure = createAction(
  '[Illness Script List API] Fetch API Failure',
  props<{ error: any }>()
);

export const getIllnessScript = createAction(
  '[Illness Script List API] Invoke Illness Script List API by Id',
  props<{ id: string }>()
);

export const getIllnessScriptSuccess = createAction(
  '[Illness Script List API] Fetch API By Id Success',
  props<{ data: IllnessScriptModel }>()
);

export const getIllnessScriptFailure = createAction(
  '[Illness Script List API] Fetch API By Id Failure',
  props<{ error: any }>()
);

export const getIllnessScriptsByProblemId = createAction(
  '[Illness Script List API] Invoke Illness Script List API by Problem Ids',
  props<{ problemId: string }>()
);

export const getIllnessScriptsByProblemIdSuccess = createAction(
  '[Illness Script List API] Fetch API By Problem Ids Success',
  props<{ problemId: string; data: IllnessScriptModel[] }>()
);

export const getIllnessScriptsByProblemIdFailure = createAction(
  '[Illness Script List API] Fetch API By Problem Ids Failure',
  props<{ error: any }>()
);

export const getIllnessScriptsByStepId = createAction(
  '[Illness Script List API] Invoke Illness Script List API by Step Ids',
  props<{ problemId: string; stepId: string }>()
);

export const getIllnessScriptsByStepIdSuccess = createAction(
  '[Illness Script List API] Fetch API By Step Ids Success',
  props<{ stepId: string; data: IllnessScriptModel[] }>()
);

export const getIllnessScriptsByStepIdFailure = createAction(
  '[Illness Script List API] Fetch API By Step Ids Failure',
  props<{ error: any }>()
);

export const getIllnessScriptsByDDxId = createAction(
  '[Illness Script List API] Invoke Illness Script List API by DDx Ids',
  props<{ problemId: string; stepId: string; ddxId: string }>()
);

export const getIllnessScriptsByDDxIdSuccess = createAction(
  '[Illness Script List API] Fetch API By DDx Ids Success',
  props<{ ddxId: string; data: IllnessScriptModel[] }>()
);

export const getIllnessScriptsByDDxIdFailure = createAction(
  '[Illness Script List API] Fetch API By DDx Ids Failure',
  props<{ error: any }>()
);

export const resetSelectedIllnessScript = createAction(
  '[Illness Script List] Reset Selected Illness Script'
);
