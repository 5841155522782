<ng-container *ngIf='illness'>
  <div class="header">
    <h2 id="title">{{illness.illnessName}}</h2>
    <div class="labels">
      <img alt="icon indicating 'emergent' property for this illness script" *ngIf="illness.emergent"
        src="assets/medical.svg">
      <span *ngIf="illness.emergent" [style.color]="'#DA1921'">Emergent</span>
      <img alt="icon indicating 'must not miss' property for this illness script" *ngIf="illness.mustNotMiss"
        src="assets/alert-triangle.svg">
      <span *ngIf="illness.mustNotMiss" [style.color]="'#B55638'">Must Not Miss</span>
    </div>
  </div>
  <div class="body">
    <div class="textbox-wrapper">
      <app-rich-text id="overview" [content]="illness.overview" />
    </div>
    <div class="illnesses-wrapper">
      <div *ngIf="images.length > 0" class="gallery">
        <h3>Related Images</h3>
        <app-images-carousel [images]="images"></app-images-carousel>
        <div [style.marginBottom]="'1rem'"></div>
      </div>
      <ng-container *ngFor='let accordion of illnessTypes; let i = index' >
        <app-accordion *ngIf='getRichText(illness, accordion.type)' [title]='accordion.title'>
          <app-rich-text [content]='getRichText(illness, accordion.type)' />
        </app-accordion>
      </ng-container>
      <app-accordion *ngIf="illness.pearlsAndPitfalls" icon="lamp-purple" type="purple" title="Pearls & Pitfalls">
        <app-rich-text id="pearlsAndPitfalls" [content]="illness.pearlsAndPitfalls" />
      </app-accordion>
    </div>
  </div>
</ng-container>