import { Observable, of } from 'rxjs';
import { TaxonomyModel } from './taxonomy.model';
import { Injectable } from '@angular/core';
import { taxonomyResponse } from './taxonomy.response';

@Injectable({
  providedIn: 'root',
})
export class TaxonomyService {
  getTaxonomyByClassification(
    product: string,
    classification: string,
    page: number,
    limit: number
  ): Observable<TaxonomyModel[]> {
    const filteredTaxonomies = taxonomyResponse.filter(
      (taxonomy) =>
        taxonomy.classification.includes(classification) &&
        (product
          ? taxonomy.title.toLowerCase().includes(product.toLowerCase())
          : true)
    );

    const startIndex = page * limit;
    const endIndex = startIndex + limit;

    return of(filteredTaxonomies.slice(startIndex, endIndex));
  }
}
