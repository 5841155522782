<ng-container class="search-wrapper">
    <app-search-input class="input-container" (clearEvent)="clearSearch()"
        (onSearchTextChange)="onSearchTextChange($event)" (onSubmitSearch)="onSearch($event)"
        [placeholder]="'Search problems and illness scripts'" [searchText]="(searchText$ | async) ?? ''"
        [autoFocus]="true" />

    <div *ngIf="(showRecentSearches$ | async)" class="recent-search">
        <div class="recent-search-info">
            <p class="subtitle">Recent searches</p>
            <ul aria-label="recent searches" tabindex="0">
                <li *ngFor='let search of (filteredRecentSearches$ | async)'>
                    <app-list-item [title]='search.title'
                        (onClick)='submitSearch(search.title)' [icon]="'/assets/search-dark.svg'"
                        [iconCTA]="'/assets/close.svg'" [iconCTALabel]="'Remove search'"
                        (onIconCTAClick)='removeRecentSearch(search.title)' />
                </li>
            </ul>
        </div>
        <div class="clear-recent-search">
            <button class="text-center" (click)='onClearSearchHistory()'>Clear search history</button>
        </div>
    </div>
    <div *ngIf="(showSearchResults$ | async)" class="search-results">
        <app-skeleton *ngIf="(isLoading$ | async)"></app-skeleton>
        <div *ngIf="(isLoading$ | async) === false">
            <p *ngIf="searchResultTotal > 0" class="subtitle">{{searchResultTotal}} results for <span
                    class="fw-bold">{{(searchText$ | async) ??
                    ''}}</span></p>
            <p *ngIf="searchResultTotal === 0" class="subtitle">No results found for <span
                    class="fw-bold">{{(searchText$ |
                    async) ?? ''}}</span></p>
            <div class="row">
                <div class="col-12 d-flex flex-wrap">
                    <div class="d-flex flex-grow-1 pb-2">
                        <app-checkbox-button class="me-2 mb-2 col-6 col-md-auto" *ngFor="let filter of filters; "
                            [checked]="filter.checked" [label]="filter.name" [value]="filter.value"
                            [count]="getCountForType(filter.name)" (onChangeCheckbox)="onFilterChange($event)" />
                    </div>
                    <div class="w-100 d-block d-md-none"></div>
                    <app-select class="select-end custom-width mb-2 ms-auto" [id]="'sort-select'" label="Sort By"
                        [options]="sortOptions" [selectedOption]="selectedSortOption"
                        (onChangeSelect)="onSortChange($event)" />
                </div>
            </div>

            <div *ngFor='let group of ((filteredSearchResults$ | async) ?? []); trackBy: trackByKey'>
                <div *ngIf="group.value.length > 0 || getCountForType(group.key) == 0" class="search-results-group">
                    <p [attr.id]="buildSectionId(group.key)" class="group-title">{{group.key}}
                        <span>({{getCountForType(group.key)}})</span>
                    </p>
                    <ng-container *ngIf="getCountForType(group.key)> 0; else noResults">
                        <ul [ariaLabel]="group.key" tabindex="0">
                            <li *ngFor='let result of group.value; trackBy: trackById'>
                                <app-list-item  [title]='result.title'
                                    (onClick)='openResult(result.id, result.type)' [icon]="'/assets/search-dark.svg'" />
                            </li>
                        </ul>
                        <div *ngIf="group.value.length > 0" class="load-more-search">
                            <button class="text-center" *ngIf="hasMoreToLoad(group.key, getCountForType(group.key))"
                                (click)="loadMore(group.key)" [attr.aria-describedby]="buildSectionId(group.key)">Load
                                More</button>
                        </div>
                    </ng-container>
                    <ng-template #noResults>
                        <p class="subtitle">No results found</p>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<app-modal #modal size="large" headerText="Illness Script" (onClose)="visibleIllness = null">
    <app-single-illness *ngIf='visibleIllness'
        [illness]="visibleIllness"></app-single-illness>
</app-modal>

