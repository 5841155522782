import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnsubscribeOnDestroyAdapter } from '../../../lib/base/unsubscribe.adapter';
import { DynamicHeadingComponent } from '../dynamic-heading/dynamic-heading.component';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  standalone: true,
  styleUrls: ['./modal.component.scss'],
  imports: [CommonModule, DynamicHeadingComponent],
})
export class ModalComponent extends UnsubscribeOnDestroyAdapter {
  @ViewChild('modal') modal!: ElementRef;
  @ViewChild('dialog') dialog!: ElementRef;
  @Input() headingLevel = 'h1';
  @Input() headerText = '';
  @Input() title = '';
  @Input() titleIcon?: string;
  @Input() size?: 'normal' | 'large' = 'normal';
  @Output() onClose = new EventEmitter();

  close() {
    this.dialog.nativeElement.close();
    this.onClose.emit();
  }

  showModal() {
    this.dialog.nativeElement.showModal();
  }

  onKeyDown(event: KeyboardEvent) {
    event.key === 'Escape' && this.close();
  }

  handleBackdropClick(event: MouseEvent): void {
    if (event.target === this.dialog.nativeElement) {
      event.stopPropagation();
      this.close();
    }
  }
}
