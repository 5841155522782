import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ScreenState {
  zoomLevel: number;
  isHighZoom: boolean;
  isVeryHighZoom: boolean;
  screenWidth: number;
  screenHeight: number;
}

@Injectable({
  providedIn: 'root',
})
export class ScreenService {
  private screenState = new BehaviorSubject<ScreenState>(
    this.getInitialState()
  );

  constructor() {
    window.addEventListener('resize', () => this.checkScreenState());
  }

  private getInitialState(): ScreenState {
    const zoomLevel = Math.round((window.outerWidth / window.innerWidth) * 100);
    return {
      zoomLevel,
      isHighZoom: zoomLevel >= 300 && zoomLevel < 400,
      isVeryHighZoom: zoomLevel >= 400,
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
    };
  }

  private checkScreenState() {
    const newState = this.getInitialState();
    if (JSON.stringify(newState) !== JSON.stringify(this.screenState.value)) {
      this.screenState.next(newState);
    }
  }

  getScreenState(): Observable<ScreenState> {
    return this.screenState.asObservable();
  }
}
