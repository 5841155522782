import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-search-input',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent {
  @Input()
  searchText = '';
  @Input() placeholder = '';
  @Input() autoFocus = false;
  @Output() onSubmitSearch = new EventEmitter<Event>();
  @Output() onSearchTextChange = new EventEmitter<Event>();

  @ViewChild('searchInput') searchInput!: ElementRef;
  @ViewChild('searchBtn') searchBtn!: ElementRef;

  ngAfterViewInit() {
    if (this.autoFocus) {
      this.keepTryingToFocus();
    }
  }

  private keepTryingToFocus() {
    let attempts = 0;
    const maxAttempts = 5;
    const interval = setInterval(() => {
      if (attempts >= maxAttempts) {
        clearInterval(interval);
        return;
      }
      this.focusInput();
      attempts++;
    }, 50);
  }

  private focusInput() {
    if (this.searchInput && this.searchInput.nativeElement) {
      this.searchInput.nativeElement.focus();
    }
  }

  clearSearch(event: Event) {
    this.searchText = '';
    this.focusInput();
    this.onSearchTextChange.emit(event);
  }

  onSubmit(event: Event) {
    this.onSubmitSearch.emit(event);
  }

  onSearchTextChangeHandler(event: Event) {
    this.onSearchTextChange.emit(event);
  }
}
