export interface DDxTableModel {
  id: string;
  illnessId: string;
  illnessName: string;
  riskFactors: string;
  symptoms: string;
  signs: string;
  emergent: boolean;
  mustNotMiss: boolean;
  workupContent: string;
}

export enum DDxTableColumn {
  RiskFactors = 'etiology_risk_factors',
  Symptoms = 'symptoms_all',
  Signs = 'signs_all',
  DiagnosesType = 'diagnoses_type',
  WorkUp = 'work_up', // We preload this info to avoid having to fetch it later, if this changes, we should add a loader to the modal when opening the Workup
}
