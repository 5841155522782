<div class="modal-backdrop" (click)="closeModal()"></div>
<div class="modal d-block {{transitionClass}}">
  <div class="modal-dialog modal-fullscreen modal-dialog-scrollable modal-size">
    <div class="modal-content d-block d-lg-none">
      <div class="modal-header">
        <button class="icon" type="button" (click)="redirectToHomePage()">
          <img src="../../../assets/mh-logo.svg" alt="Mc Graw Hill logo">
          <p class="title">Clinical Reasoning</p>
        </button>
        <button
           id='close-btn'
           type='button'
           #closeBtn
           aria-label='Close'
           [ngClass]='classes'
           (click)='closeModal()'
        ><img alt='close icon' [src]='icon' /></button>
      </div>
      <div class="modal-body">
        <button type="button" class="my-account-button" (click)="openDropdown = !openDropdown" >
          <img src="../../../../../assets/my-account-icon.svg" alt="my account icon" class="my-account icon">
          <p class="home-text">My Account</p>
          <img src="../../../../../assets/chevron-up-white.svg" alt="arrow down icon" class="chevron-icon" [class.rotate]="!openDropdown">
        </button>
        <div class="account-options" *ngIf="openDropdown" [class.dropdown-animation]="!openDropdown">
          <p class="options" (click)="openAccountDetails()">My Account Details</p>
          <p class="options" (click)="signOut()">Sign Out</p>
        </div>
      </div>
    </div>
  </div>
</div>
