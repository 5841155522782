// working-ddx.reducer.ts
import { createReducer, on } from '@ngrx/store';
import * as ddxTableActions from './ddx-table.actions';
import { DDxTableModel } from '../ddx-table';

export interface ddxTableState {
  ddxTable: DDxTableModel[];
  loading?: boolean;
  error: any;
}

export const initialState: ddxTableState = {
  ddxTable: [],
  loading: false,
  error: null,
};

export const ddxTableReducer = createReducer(
  initialState,
  on(ddxTableActions.getDDxTable, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(ddxTableActions.getDDxTableSuccess, (state, { ddxTable }) => ({
    ...state,
    ddxTable,
    loading: false,
  })),
  on(ddxTableActions.getDDxTableFailure, (state, { error }) => ({
    ...state,
    error,
    loading: false,
  }))
);
