<div class="toggle-container">
    <input tabindex="-1" #checkRef type='checkbox' class='btn-check visually-hidden' id='checkbox-btn-{{value}}' autocomplete='off'
        [value]='value' [checked]="checked" [(ngModel)]="checked" (change)="onChange($event)">

    <label 
        [ariaChecked]="checked" 
        role="checkbox" 
        tabindex="0" 
        #labelRef 
        (keydown)="onKeyDown($event)" 
        for='checkbox-btn-{{value}}' 
        class='btn toggle-btn' 
        [ngClass]="checkedBackground"
    >
        {{ label }}
        <span *ngIf="count > 0">({{ count }})</span>
    </label>
</div>