import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import ButtonComponent from 'src/app/components/button/button.component';
import { RadioGroupModule } from 'src/app/components/radio-group/radio-group.module';
import { RadioOptions } from 'src/app/components/radio-group/RadioOptionsModel';
import { StepType } from 'src/lib/step/step';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MOBILE_MAX_WIDTH_PIXELS } from 'src/app/step/step.constants';
import { StepContentService } from 'src/lib/step/step-content.service';
import { first } from 'rxjs';
import { Router } from '@angular/router';
import { ScreenService } from 'src/lib/screen/screen.service';

@Component({
  selector: 'app-step-cta',
  standalone: true,
  imports: [CommonModule, ButtonComponent, RadioGroupModule],
  templateUrl: './step-cta.component.html',
  styleUrls: ['./step-cta.component.scss'],
})
export class StepCTAComponent implements OnInit {
  @HostBinding('class.high-zoom') isHighZoom = false;
  @HostBinding('class.very-high-zoom') isVeryHighZoom = false;

  @Input() options: RadioOptions[] | null = [];
  @Input() type: StepType = StepType.QUESTION;
  @Output() clicked: EventEmitter<number> = new EventEmitter();

  isMobile = false;
  readonly breakpoint$ = this.breakpointObserver.observe([
    `(max-width: ${MOBILE_MAX_WIDTH_PIXELS}px)`,
  ]);

  constructor(
    private breakpointObserver: BreakpointObserver,
    private stepContentService: StepContentService,
    private router: Router,
    private screenService: ScreenService
  ) {}

  ngOnInit(): void {
    this.breakpoint$.subscribe((data) => {
      this.isMobile = data.matches;
    });

    this.screenService.getScreenState().subscribe((state) => {
      this.isHighZoom = state.isHighZoom && !state.isVeryHighZoom;
      this.isVeryHighZoom = state.isVeryHighZoom;
    });
  }

  onClick(optionIndex = 0) {
    this.clicked.emit(optionIndex);
  }

  onGoBack() {
    this.stepContentService.previousPath$.pipe(first()).subscribe((path) => {
      this.router.navigate([path]);
    });
  }
}
