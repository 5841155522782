<div ngbAccordion [ngClass]="classes" [ariaExpanded]="openedByDefault">
	<div ngbAccordionItem [collapsed]='!openedByDefault'>
		<h2 tabindex="0" ngbAccordionHeader (keydown)="onKeyDown($event)">
			<button #header tabindex="-1" ngbAccordionButton class="accordion-button">
				<img alt="" *ngIf='icon' [src]="'assets/' + icon + '.svg'" />
				{{ title }}
      		</button>
		</h2>
		<div ngbAccordionCollapse>
			<div ngbAccordionBody>
				<div class="text-box">
					<ng-content></ng-content>
				</div>
			</div>
		</div>
	</div>
</div>
