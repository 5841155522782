import { Action, createReducer, on } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import * as RouterActions from './router.actions';
import { RouteIds, RouterStateUrl } from './router';

export interface RouterState {
  routerState: RouterReducerState<RouterStateUrl>;
  routerIds: Partial<RouteIds>;
}

const initialRouterState: RouterReducerState<RouterStateUrl> = {
  state: {
    url: '',
    params: {},
    queryParams: {},
  },
  navigationId: 0,
};

export const initialState: RouterState = {
  routerState: initialRouterState,
  routerIds: {},
};

const customReducer = createReducer(
  initialState,
  on(RouterActions.urlChangedSuccess, (state, { routerIds }) => ({
    ...state,
    routerIds,
  })),
  on(RouterActions.urlChangedFailure, (state) => ({
    ...state,
    routerIds: {},
  }))
);

export function routerCustomReducer(
  state: RouterState | undefined,
  action: Action
) {
  const customState = customReducer(state, action);
  return {
    ...customState,
    routerState: routerReducer(customState.routerState, action),
  };
}
