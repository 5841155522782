import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-link',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class LinkComponent {
  @Input()
  label = 'Link';

  @Output()
  onClick = new EventEmitter<Event>();

  clicked($event: Event) {
    this.onClick.emit($event);
    return false;
  }
}
