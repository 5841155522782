import { ImageViewModel } from '../../app/components/images-carousel/images-carousel.types';

export interface Manifestation {
  problemType?: string[];
  id: string;
  displayName: string;
  summary: string;
  aliases: string[];
  images?: ImageViewModel[];
  nextSteps: string[];
  problemStatement: string;
}

export enum ProblemNodeTemplate {
  SUMMARY = 'summary_of_approach',
  ALIASES = 'aliases',
  IMAGES = 'media_gallery',
  PROBLEM_STATEMENT = 'problem_statement',
}
