import { createAction, props } from '@ngrx/store';
import { NavigationExtras } from '@angular/router';
import { RouteIds } from './router';

export const go = createAction(
  '[Router] Go',
  props<{ path: any[]; query?: object; extras?: NavigationExtras }>()
);

export const back = createAction('[Router] Back');
export const forward = createAction('[Router] Forward');

export const urlChanged = createAction(
  '[Router] URL Changed',
  props<{ url: string }>()
);

export const urlChangedSuccess = createAction(
  '[Router] URL Changed Success',
  props<{ routerIds: Partial<RouteIds> }>()
);

export const urlChangedFailure = createAction(
  '[Router] URL Changed Failure',
  props<{ error: any }>()
);
