import { createAction, props } from '@ngrx/store';
import {
  StepModel,
  StepItem,
  HistoryStepItem,
  StepContextualInfo,
} from '../step';
import { DifferentialListModel } from 'src/lib/differential/differential';
import { HierarchyNode } from 'src/lib/mock-integration/data/data.model';

// This action accesses data from the mock API.
export const getStep = createAction(
  '[Step API] Invoke Step Fetch API By Id',
  props<{ id: string }>()
);

export const getStepSuccess = createAction(
  '[Step API] Fetch API By Id Success',
  props<{ step: StepModel }>()
);

// This action accesses data from the mock hierarchy data found in assets/data.
export const fetchStep = createAction(
  '[Step API] Invoke Step Data Fetch API By Id',
  props<{ manifestationId: string; stepId: string }>()
);

export const fetchStepSuccess = createAction(
  '[Step API] Invoke Step Fetch API By Id Success',
  props<{ step: StepModel }>()
);

export const fetchSteps = createAction(
  '[Step API] Invoke Step Data Fetch API By list of IDs',
  props<{ stepIDs: string[] }>()
);

export const fetchStepsSuccess = createAction(
  '[Step API] Invoke Step Data Fetch API By list of IDs Success',
  props<{ steps: StepModel[] }>()
);

export const fetchDifferential = createAction(
  '[Step API] Invoke Differential Fetch API By ID',
  props<{ manifestationID: string; differentialID: string }>()
);

export const fetchDifferentialSuccess = createAction(
  '[Step API] Invoke Differential Fetch API By ID Success',
  props<{ differential: DifferentialListModel }>()
);

export const fetchDifferentials = createAction(
  '[Step API] Invoke Step Differential Fetch API By list of IDs',
  props<{ differentialIDs: string[] }>()
);

export const fetchDifferentialsSuccess = createAction(
  '[Step API] Invoke Step Differential Fetch API By list of IDs Success',
  props<{ differentials: DifferentialListModel[] }>()
);

export const getSteps = createAction(
  '[Step API] Invoke Next Step Fetch API By list of Ids',
  props<{ stepIds: string[] }>()
);

export const getStepsSuccess = createAction(
  '[Step API] Fetch API By list of Ids Success',
  props<{ steps: StepModel[] }>()
);

export const setProblem = createAction(
  '[Step API] Set Problem in Critical Reasoning Flow',
  props<{ id: string }>()
);

export const setCurrentStep = createAction(
  '[Step API] Set Current Step in Critical Reasoning Flow',
  props<{ step: StepItem }>()
);

export const setProblemHierarchy = createAction(
  '[Step Progress] Set Problem Hierarchy',
  props<{ hierarchy: HierarchyNode }>()
);

export const addHistoryStep = createAction(
  '[Step Progress] Add step to StepHistory',
  props<{ item: HistoryStepItem }>()
);

export const removeHistoryStep = createAction(
  '[Step Progress] Remove step from StepHistory',
  props<{ id: string }>()
);

export const clearStepHistory = createAction(
  '[Step Progress] Clears StepHistory'
);

export const fetchContextualInformation = createAction(
  '[Step API] Set Contextual Information',
  props<{ manifestationId: string; stepId: string; differentialId: string }>()
);

export const setContextualInformationSuccess = createAction(
  '[Step API] Set Contextual Information Success',
  props<{ contextualInformation: StepContextualInfo }>()
);

export const clearState = createAction('[Step API] Clear State');

export const setCurrentTable = createAction(
  '[Step API] Set Id for current ddx table',
  props<{ id: string }>()
);

export const clearCurrentTable = createAction(
  '[Step API] Resets current ddx table id'
);
