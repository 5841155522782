// working-ddx.effects.ts
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ddxTablections from './ddx-table.actions';
import { DDxTableService } from '../ddx-table.service';

@Injectable()
export class DDxTableEffects {
  constructor(
    private actions$: Actions,
    private ddxTableService: DDxTableService
  ) {}

  loadDDxTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ddxTablections.getDDxTable),
      mergeMap((action) => {
        return this.ddxTableService
          .get(action.problemId, action.stepId, action.ddxId)
          .pipe(
            map((data) => {
              return ddxTablections.getDDxTableSuccess({ ddxTable: data });
            }),
            catchError((error) => {
              console.error('Error loading DDxTable:', error);
              return of(ddxTablections.getDDxTableFailure({ error }));
            })
          );
      })
    )
  );
}
